<template>
  <div id="app"><h1 style="line-height:25px"><img src="./assets/omnibot_logos/group-9.png" alt="imageNotFound" style="width:25px;height:25px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Omnibot</h1>
    <amplify-chatbot v-bind:chatbotConfig="chatbotConfig"></amplify-chatbot>
  </div>
</template>

<script>

import { Interactions } from "aws-amplify";

export default {
  name: 'App',

  data: () => ({
    chatbotConfig: {
      bot: "autoOnboardingChatbotNew_dev",
      clearComplete: false
    },
  }),
  mounted() {
    Interactions.onComplete("autoOnboardingChatbotNew_dev", this.handleComplete);
  },
  methods: {

    handleComplete(err,confirmation) {
      
      if (err) {
        alert("bot conversation failed");
        return;
      }

      var slotValueOG=JSON.stringify(confirmation,null,2);
      var info = JSON.parse(slotValueOG)["slots"];
      var data;
      var msg;
      var msg2;

      if(slotValueOG.includes("Name")){
        var firstName = info["firstName"];
        var lastName = info["lastName"];
        var driverLicenseNo = info["license"];

        data = {"schemaVersion": 1,"firstName": firstName, "lastName": lastName, "driverLicenseNo":driverLicenseNo};
        msg = {"route" : "/addDriver", "data" : data};
        msg2 = JSON.stringify(msg);

        window.omni_command.postMessage(msg2);

      }

      else if(slotValueOG.includes("annualMileage")){

        var annualMileage=info["annualMileage"];
        var VINnumber = info["VINnumber"];

        data = {"schemaVersion":1,"annualMileage":annualMileage,"VINnumber":VINnumber};
        msg = {"route":"/addAuto","data":data};
        msg2 = JSON.stringify(msg);

        window.omni_command.postMessage(msg2);


      }

      else if(slotValueOG.includes("amount")){
        // p ="Amount: "+ info["amount"];
        // d="payment";
      }

      var slotValue=info["options"];
      var slotValueLower=JSON.stringify(slotValue).toLowerCase();

      if(slotValueLower.includes("coverage")) {
        msg = {"route":"/coverageTerm"};
        msg2 = JSON.stringify(msg);

        window.omni_command.postMessage(msg2);
      }

      else if(slotValueLower.includes("stats")) {

        msg = {"route":"/allState"};
        msg2 = JSON.stringify(msg);

        window.omni_command.postMessage(msg2);

      }


      return;
    },
  },
};

</script>

<style>
  @font-face{
    font-family: "Gentona";
    src:local("Gentona"),
    url(./fonts/Gentona/Gentona-Book.otf)
  }
</style>

<style>

#app .amplify-interactions-button[data-v-28f9686f]{
  background: url("assets/omnibot_sendbutton/group-28.png") 100% no-repeat var(--color-white);
}

#app .amplify-interactions-input[data-v-28f9686f]{
  background-color: #ffffff;
  color: #212D3F;
  text-align: right;
  border-style: solid;
  border-color: #3FBAD7;
  border-width: 2px;
}

#app .amplify-interactions-response[data-v-28f9686f]{
  background-image: linear-gradient(to right, #C36CCB,#5D97E0);
  color: #ffffff;
  white-space: pre-wrap;
  text-align: left;
}  
</style>

<style>
#app {
  font-family: Gentona;
  text-align: center;
  margin-top: 10px;
  position: relative;
}
</style>
