/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:3a9b1e9c-5d7c-4d4d-a55a-118861c2a0d2",
    "aws_cognito_region": "us-east-1",
    "oauth": {},
    "aws_bots": "enable",
    "aws_bots_config": [
        {
            "name": "OrderFlowers_dev",
            "alias": "$LATEST",
            "region": "us-east-1"
        },
        {
            "name": "autoOnboardingChatbotNew_dev",
            "alias": "$LATEST",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "awsamplifychatbot-20210129104617-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://awsamplifychatbot-20210129104617-hostingbucket-dev.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
